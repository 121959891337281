.app {
  --header: 3.75rem;
  --footer: 3.75rem;

  display: grid;
  grid-template:
    "header" var(--header)
    "content" auto
    "footer" var(--footer)
    / auto;

  height: 100vh;
  max-height: 100vh;
  width: auto;
}

.content {
  grid-area: content;
  background-color: #eee;
  padding: 1rem;
}

.header {
  grid-area: header;
  min-width: 340px;
}

.footer {
  grid-area: footer;
  max-height: var(--footer);
  position: sticky;
  bottom: 0;
  overflow: hidden;
}
